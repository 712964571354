@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.navbar {
  padding: 40px 0 30px 0;
  border: 1px solid;
  border-top: 0;
  border-image-slice: 1;
  border-image-source: $main-gradient;

  @include respond-to(medium) {
    padding: 12px 0;
  }
}

.navbarRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.burger {
  width: 30px;
}

.walletGroup {
  display: flex;
  gap: 20px;
}