@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.about {
  padding: 60px 0;
  border: 1px solid;
  border-image-slice: 1;
  border-image-source: $main-gradient;

  @include respond-to(medium) {
    padding: 30px 0;
  }
}

.aboutCards {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.aboutCard {
  position: relative;
  max-width: 518px;
  width: 100%;
  min-height: 370px;

  @include respond-to(medium) {
    max-width: 400px;
    min-height: 260px;
  }

  @include respond-to(small) {
    max-width: 270px;
    min-height: 230px;
  }
}

.img {
  position: relative;
  z-index: 5;

  img {
    @include fluidImage;
  }
}

.text {
  position: absolute;
  z-index: 10;
  bottom: 10px;
  text-align: center;

  > h2 {
    font-size: $title-size;
    line-height: $title-size-height;
    font-weight: 600;
    margin-bottom: 10px;

    @include respond-to(medium) {
      font-size: $base-size;
      line-height: $base-size-height;
    }
  }

  > p {
    font-size: $medium-size;
    line-height: $medium-size-height;

    @include respond-to(medium) {
      font-size: $small-size;
      line-height: $small-size-height;
    }
  }
}