@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 32px;

  @include respond-to(medium) {
    gap: 18px;
  }
}