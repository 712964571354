@import "src/assets/styles/helpers/mixin";

.bannerAdmin {
  max-width: 512px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  user-select: none;
  border: 1px solid #fff;

  @include respond-to(medium) {
    height: 120px;
  }
}
