@import "src/assets/styles/helpers/mixin";

.banner {
  max-width: 512px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  user-select: none;
  border: 1px solid #fff;
  cursor: pointer;

  > img {
    border-radius: 10px;
    width: 100%;
    height: 200px;

    @include respond-to(medium) {
      height: 120px;
    }
  }

  @include respond-to(medium) {
    height: 120px;
  }
}

.link {
  position: absolute;
  inset: 0;
}
