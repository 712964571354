.container {
  max-width: 512px;
  margin: 0 auto;
}

.image {
  max-width: 512px;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 25px;
}