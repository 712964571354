@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.form {
  text-align: left;
}

.wrap {
  text-align: right;
}

.label {
  position: relative;

  > i {
    position: absolute;
  }

  > .persons {
    top: 70%;
    left: 13px;
    transform: translateY(-55%);
  }

  > .plus {
    top: 70%;
    right: 13px;
    transform: translateY(-55%);
  }
}

.input {
  height: 45px;
  padding: 13px 50px;
  border: 1px solid var(--yellow);
  background: var(--light-black);
  outline: none;
  width: 100%;
  border-radius: 20px;
  font-family: $font-base;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--light-gray);

  @include placeHolderSettings {
    color: var(--light-gray);
  }
}

.errorMessage {
  margin-top: 10px;
  display: inline-flex;
  align-items: start;
  color: var(--error);
  font-weight: 600;
  gap: 9px;
  padding-left: 5px;
}