:root {
  --body-font-family: 'Roboto', sans-serif;
  --body-font-size: 1rem;
  --body-font-weight: 400;
  --body-line-height: 1.25rem;
  --body-color: #CECECE;
  --wrapper-background: #000;
  --yellow: rgba(250, 214, 75, 1);
  --light-black: #0C0C0C;
  --light-gray: #BFBFBF;
  --error: #E53535
}