.wrapper {
  max-width: 370px;
  text-align: center;
  margin: 0 auto;
}

.message {
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--light-gray);
}