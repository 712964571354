@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.descriptionArea {
  margin-top: 30px;
  max-width: 100%;
  width: 100%;
  height: 150px;
  border: 1px solid var(--yellow);
  background: var(--light-black);
  border-radius: 16px;
  padding: 13px;
  resize: none;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--light-gray);
  outline: none;

  @include placeHolderSettings {
    color: var(--light-gray);
    font-family: $font-base;
  }
}

.errorMessage {
  color: var(--light-gray);
  display: inline-flex;
  align-items: start;
  gap: 6px;
  margin-top: 5px;

  &.error {
    color: var(--error);
    font-weight: 600;
  }
}

.messages {
  display: flex;
  justify-content: space-between;
}