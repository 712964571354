@import "src/assets/styles/helpers/mixin";
@import "src/assets/styles/helpers/variables";

.addBanner {
  max-width: 512px;
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0C0C0C;
  border: 1px solid #FFFFFF;
  border-radius: 10px;

  @include respond-to(medium) {
    height: 120px;
  }
}